
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useAuth } from '@/composables/useAuth'
import { useNotifications } from '@/composables/useNotifications'
import { ERoles } from '@/enums/roles'
export default defineComponent({
    name: 'LoginForm',
    setup() {
        const { login, role, firstLogin, setGuid } = useAuth()
        const { setNotification, status } = useNotifications()
        const email = ref('')
        const password = ref('')
        const router = useRouter()
        const route = useRoute()

        if (route.query.guid) {
            const guid = route.query.guid as string
            setGuid(guid)
        }
        const submitForm = async () => {
            switch (true) {
                case password.value === '' && email.value === '':
                    setNotification('Uzupełnij email i hasło')
                    return false
                case email.value === '':
                    setNotification('Uzupełnij email')
                    return false
                case password.value === '':
                    setNotification('Uzupełnij hasło')
                    return false
            }

            const response = await login(email.value, password.value)
            if (response) {
                if (firstLogin.value) {
                    return router.push({ name: 'NewPasswordForm' })
                }
                if (role.value === ERoles.Manager) {
                    router.push({ name: 'CarWashList' })
                }
                if (role.value === ERoles.Courier) {
                    router.push({ name: 'DeliveryList' + role.value })
                }
            }
        }

        return {
            status,
            router,
            email,
            password,
            submitForm,
        }
    },
})
